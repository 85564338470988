import React from "react";
import { Header } from "../components/general/header";
import { Hero } from "../components/design/hero";
import { About } from "../components/design/about";
import Projects from "../components/design/projects";
import Mprojects from "../components/design/mprojects";
import { How } from "../components/design/how";
import { FAQ } from "../components/design/faq";
import { Feedback } from "../components/design/feedback";
import { Footer } from "../components/general/footer";
import { What2 } from "../components/design/what";
import { Service } from "../components/design/service";
import { StaticCarousel } from "../components/design/hero";
import { Features } from "../components/design/features";
import { Why } from "../components/design/why";

const Design = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Features />
      <Projects />
      <Mprojects />
      <How />
      <Feedback />
      <FAQ />
      <Footer />
    </div>
  );
};

export default Design;
