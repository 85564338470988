import React from "react";
import Interior2 from "../../assets/images/iprojects/iproject2/iproject2-1.webp";
import Modal2 from "../general/imodals/modal2";

const Project2 = () => {
  return (
    <div class="h-full bg-white rounded-lg shadow">
      <a>
        <img
          class="rounded-t-lg object-cover h-48 w-full"
          src={Interior2}
          alt=""
        />
      </a>
      <div class="p-8 realtive">
        <Modal2 />
        <p class="mb-3 text-lg text-gray-700 line-clamp-5">
          Заказчик проекта хотел интерьер в морской тематике, но при этом,
          спальня для дочери должна была быть более нежная. Получилось шикарное
          сочетание оттенков в одном проекте.
        </p>
      </div>
    </div>
  );
};

export default Project2;
