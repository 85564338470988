import React, { useState } from "react";

import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

import Project1 from "../projects/project1";
import Project2 from "../projects/project2";
import Project3 from "../projects/project3";
import Project4 from "../projects/project4";
import Project5 from "../projects/project5";
import Project6 from "../projects/project6";
import ProjectInfo from "../projects/projectinfo";

const Projects = () => {
  const [currentComponentArrayIndex, setCurrentPhotoIndex] = useState(0);

  const handleNextClick = () => {
    if (currentComponentArrayIndex < componentArray.length - 1) {
      setCurrentPhotoIndex(currentComponentArrayIndex + 1);
    }
  };

  const handlePrevClick = () => {
    if (currentComponentArrayIndex === 0) return;
    else setCurrentPhotoIndex(currentComponentArrayIndex - 1);
  };

  const componentArray = [
    <>
      <Project1 />
      <Project2 />
      <Project3 />
    </>,
    <>
      <Project4 />
      <Project5 />
      <ProjectInfo />
    </>,
    <>
      <Project6 />
    </>,
  ];

  return (
    <div id="projects" className="hidden md:block bg-zinc-800 md:py-20 p-5">
      <div className="mx-auto md:max-w-7xl">
        <h2 className="text-3xl md:text-5xl mb-10 md:mb-20 uppercase text-white">
          Проекты
        </h2>
        <div className="relative flex">
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4 z-10">
            {componentArray[currentComponentArrayIndex]}
          </div>
          <div className="flex justify-between w-full h-full items-center absolute -ml-5">
            <button
              onClick={handlePrevClick}
              class="p-2.5 z-20 relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium rounded-lg group shadow bg-amber-400 hover:bg-zinc-200">
              <ChevronLeftIcon className="w-5 h-5 rounded" />
            </button>
            <button
              onClick={handleNextClick}
              class="-mr-10 z-20 p-2.5 relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium rounded-lg group shadow bg-amber-400 hover:bg-zinc-200">
              <ChevronRightIcon className="w-5 h-5 rounded" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
