import React from "react";
import Interior1 from "../../assets/images/projects/project1/project1-1.webp";
import Modal1 from "../general/modals/modal1";

const Project1 = () => {
  return (
    <div class="h-full bg-white rounded-lg shadow">
      <a>
        <img
          class="rounded-t-lg object-cover h-48 w-full"
          src={Interior1}
          alt=""
        />
      </a>
      <div class="p-8 realtive">
        <Modal1 />
        <p class="mb-3 text-lg text-gray-700 line-clamp-5">
          Интерьер, где преобладают светлые оттенки дерева привлекает своей
          уютностью. Еще больше манят обитые тканью мягкие барные стулья. Барный
          стол органично отделяет зону кухни от зоны отдыха. Теплый интерьер
          дополняется изящной люстрой в виде цветочных веточек.
        </p>
      </div>
    </div>
  );
};

export default Project1;
