import React from "react";
import Interior3 from "../../assets/images/iprojects/iproject3/iproject3-1.webp";
import Modal3 from "../general/imodals/modal3";

const Project3 = () => {
  return (
    <div class="h-full bg-white rounded-lg shadow">
      <a>
        <img
          class="rounded-t-lg object-cover h-48 w-full"
          src={Interior3}
          alt=""
        />
      </a>
      <div class="p-8 realtive">
        <Modal3 />
        <p class="mb-3 text-lg text-gray-700 line-clamp-5">
          Эта квартира в стиле лофт сразу завораживает сочетанием грубого бетона
          в отделке стен и изящных люстр из стекла. Этот интерьер делает ставку
          на контрасты.
        </p>
      </div>
    </div>
  );
};

export default Project3;
