import React from "react";
import Card1 from "../../assets/images/card1.svg";
import Card2 from "../../assets/images/card2.svg";
import Card3 from "../../assets/images/card3.svg";
import Card4 from "../../assets/images/card4.svg";
import Card5 from "../../assets/images/card5.svg";

export const Features = () => {
  return (
    <div className="bg-zinc-100 md:py-20 p-10">
      <div className="mx-auto md:max-w-7xl">
        <h2 className="text-3xl md:text-5xl mb-10 md:mb-20 uppercase text-zinc-800">
          Что вы получаете?
        </h2>
        <div className="flex justify-center">
          <div className="grid grid-cols-2 grid-rows-3 md:grid-cols-3 md:grid-rows-2 xl:grid-cols-5 xl:grid-rows-1 gap-2 lg:gap-4">
            <div class="flex-auto bg-white text-center rounded-lg shadow-lg pt-6 px-3 lg:pt-10 lg:px-5">
              <div className="flex flex-col items-center">
                <img className="w-1/5" src={Card4} />
                <h5 class="text-md md:text-xl lg:text-2xl tracking-tight text-gray-900 py-2">
                  Готовое решение
                </h5>
                <p class="text-center text-xs md:text-sm lg:text-md font-normal text-gray-900 pb-4 pb-8">
                  Наш дизайнер подготовит дизайн-проект вашей квартиры исходя из
                  выбранного вами готового интерьерного решения
                </p>
              </div>
            </div>

            <div class="flex-auto bg-white text-center rounded-lg shadow-lg pt-6 px-3 lg:pt-10 lg:px-5">
              <div className="flex flex-col items-center">
                <img className="w-1/5" src={Card1} />
                <h5 class="text-md md:text-xl lg:text-2xl tracking-tight text-gray-900 py-2">
                  Визуализация
                </h5>
                <p class="text-center text-xs md:text-sm lg:text-md font-normal text-gray-900 pb-4 pb-8">
                  Покажем ваш интерьер в 3D или VR, сможете прогуляться по своей
                  будущей квартире!
                </p>
              </div>
            </div>

            <div class="flex-auto bg-white text-center rounded-lg shadow-lg pt-6 px-3 lg:pt-10 lg:px-5">
              <div className="flex flex-col items-center">
                <img className="w-1/5" src={Card2} />
                <h5 class="text-md md:text-xl lg:text-2xl tracking-tight text-gray-900 py-2">
                  Смета
                </h5>
                <p class="text-center text-xs md:text-sm lg:text-md font-normal text-gray-900 pb-4 pb-8">
                  Составим полную смету ремонта и меблировки, и рассчитаем
                  нужное количество материалов
                </p>
              </div>
            </div>

            <div class="flex-auto bg-white text-center rounded-lg shadow-lg pt-6 px-3 lg:pt-10 lg:px-5">
              <div className="flex flex-col items-center">
                <img className="w-1/5" src={Card3} />
                <h5 class="text-md md:text-xl lg:text-2xl tracking-tight text-gray-900 py-2">
                  Сопровождение
                </h5>
                <p class="text-center text-xs md:text-sm lg:text-md font-normal text-gray-900 pb-4 pb-8">
                  Подберем все необходимые товары в ТЦ «Люблинское поле»
                  согласно проекту, оформим доставку
                </p>
              </div>
            </div>
            <div class="flex-auto bg-white text-center rounded-lg shadow-lg pt-6 px-3 lg:pt-10 lg:px-5">
              <div className="flex flex-col items-center">
                <img className="w-1/5" src={Card5} />
                <h5 class="text-md md:text-xl lg:text-2xl tracking-tight text-gray-900 py-2">
                  Бесплатно
                </h5>
                <p class="text-center text-xs md:text-sm lg:text-md font-normal text-gray-900 pb-4 pb-8">
                  Вы платите только за материалы и мебель, при этом экономите
                  время и деньги!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
