import React from "react";
import { Header } from "../components/general/header";
import { Ihero } from "../components/idesign/ihero";
import { Ifix } from "../components/idesign/ifix";
import Iprojects from "../components/idesign/iprojects";
import Improjects from "../components/idesign/improjects";
import { Ihow } from "../components/idesign/ihow";
import { Ifeatures } from "../components/idesign/ifeatures";
import { Iservice } from "../components/idesign/iservice";
import { Feedback } from "../components/design/feedback";
import { Footer } from "../components/general/footer";

const iDesign = () => {
  return (
    <div>
      <Header />
      <Ihero />
      <Ifix />
      <Iprojects />
      <Improjects />
      <Ihow />
      <Ifeatures />
      <Feedback />
      <Iservice />
      <Footer />
    </div>
  );
};

export default iDesign;
