import React from "react";
import { Navigation } from "./navigation";

export const Header = () => {
  return (
    <header className="mx-auto md:max-w-7xl">
      <Navigation />
    </header>
  );
};
