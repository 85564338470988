import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import CallBack from "../hooks/callback";

export default function Modal3() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal3() {
    setIsOpen(false);
  }

  function openModal3() {
    setIsOpen(true);
  }

  return (
    <>
      <button
        type="button"
        onClick={openModal3}
        class="py-2 my-2 btn btn3 text-black bg-gray-100 hover:bg-gray-200 hover:italic focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg px-2.5 py-2.5 text-center inline-flex items-center"
      >
        <span class="btn-text-one">Получить консультацию</span>
        <span class="btn-text-two">Получить консультацию</span>
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal3}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="transform transition duration-[400ms]"
                enterFrom="opacity-0 rotate-[-120deg] scale-50"
                enterTo="opacity-100 rotate-0 scale-100"
                leave="transform duration-200 transition ease-in-out"
                leaveFrom="opacity-100 rotate-0 scale-100 "
                leaveTo="opacity-0 scale-95 "
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white/90 backdrop-blur-lg p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-medium leading-6 text-gray-900 mb-4"
                  >
                    Мы можем связаться с вами!
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-gray-500 mb-4">
                      Оставьте ваши контактные данные и мы перезвоним вам в
                      течении рабочего дня.
                    </p>
                  </div>

                  <CallBack />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
