import React from "react";
import Interior1 from "../../assets/images/projects/project5/project5-1.webp";
import Modal5 from "../general/modals/modal5";

const Project1 = () => {
  return (
    <div class="h-full bg-white rounded-lg shadow">
      <a>
        <img
          class="rounded-t-lg object-cover h-48 w-full"
          src={Interior1}
          alt=""
        />
      </a>
      <div class="p-8 realtive">
        <Modal5 />
        <p class="mb-3 text-lg text-gray-700 line-clamp-5">
          Этот интерьер - воздушный и стильный одновременно. Бетонная отделка
          стен органично сочетается с мягкими складками массивных штор и обивкой
          изголовья кровати. Текстиль создает ощущение уюта. Хромированные
          элементы в гостинной дают мягкое мерцание отражающегося света.
        </p>
      </div>
    </div>
  );
};

export default Project1;
