import React from "react";
import Interior1 from "../../assets/images/projects/project6/project6-1.webp";
import Modal6 from "../general/modals/modal6";

const Project6 = () => {
  return (
    <div class="h-full bg-white rounded-lg shadow">
      <a>
        <img
          class="rounded-t-lg object-cover h-48 w-full"
          src={Interior1}
          alt=""
        />
      </a>
      <div class="p-8 realtive">
        <Modal6 />
        <p class="mb-3 text-lg text-gray-700 line-clamp-5">
          Ультрасовременный стиль лофт разбивает все устоявшиеся представления
          об интерьере. Такой дизайн подходит тем, кто любит идти в ногу со
          временем. Грубая отделка стен из бетона органично сочетается с изящной
          стеклянной люстрой и воздушной тюлью на окне. Лепнина на стенах
          завершает этот дуэт противоположностей и придает утонченности.
        </p>
      </div>
    </div>
  );
};

export default Project6;
