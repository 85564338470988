import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export default function Modal5() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal5() {
    setIsOpen(false);
  }

  function openModal5() {
    setIsOpen(true);
  }

  return (
    <>
      <button
        type="button"
        onClick={openModal5}
        class=" btn btn3 text-black bg-zinc-700 hover:bg-amber-300 hover:italic rounded-lg px-2.5 py-2.5 text-center inline-flex items-center">
        <span class="btn-text-one text-white">Заказать</span>
        <span class="btn-text-two text-white">Заказать</span>
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal5}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="transform transition duration-[400ms]"
                enterFrom="opacity-0 rotate-[-120deg] scale-50"
                enterTo="opacity-100 rotate-0 scale-100"
                leave="transform duration-200 transition ease-in-out"
                leaveFrom="opacity-100 rotate-0 scale-100 "
                leaveTo="opacity-0 scale-95 ">
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white/90 backdrop-blur-lg p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-medium leading-6 text-gray-900 mb-4">
                    Нужна консультация?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-gray-500 mb-4">
                      Оставьте контактные данные, и мы Вам перезвоним
                    </p>
                  </div>
                  <form action="#">
                    <div class="flex flex-col justify-start">
                      <div>
                        <label
                          for="first_name"
                          class="block mb-2 text-sm font-bold text-gray-900">
                          Ваше имя
                        </label>
                        <input
                          type="text"
                          id="first_name"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4"
                          placeholder="Как к вам обращаться"
                          required
                        />
                      </div>
                      <div>
                        <label
                          for="phone"
                          class="block mb-2 text-sm font-bold text-gray-900">
                          Номер телефона
                        </label>
                        <input
                          type="tel"
                          id="phone"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4"
                          placeholder="000-000-00-00"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          required
                        />
                      </div>
                      <div>
                        <input
                          id="link-checkbox"
                          type="checkbox"
                          value=""
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="link-checkbox"
                          class="ml-2 text-sm font-medium text-gray-900">
                          Я согласен с
                          <a
                            href="#"
                            class="text-blue-600 dark:text-blue-500 hover:underline">
                            {" "}
                            политикой конфиденциальности
                          </a>
                          .
                        </label>
                      </div>
                    </div>
                  </form>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-amber-400 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal5}>
                      Отправить
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
