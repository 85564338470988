import React from "react";
import Flat from "../../assets/images/flat.webp";
import Flat1 from "../../assets/images/flat1.webp";
import Flat2 from "../../assets/images/flat2.webp";
import Flat3 from "../../assets/images/flat3.webp";
import Modal6 from "../general/modal6";

export const Ifix = () => {
  return (
    <div className="bg-zinc-700 md:py-20 p-10">
      <div className="mx-auto md:max-w-7xl">
        <h2 className="text-3xl md:text-5xl mb-10 md:mb-20 uppercase text-white">
          Цены на дизайн-проекты квартир
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
          <div class="flex">
            <div class="flex-auto bg-white rounded-lg shadow-lg">
              <div className="flex flex-col">
                <h3 class="xs:text-md md:text-xl xl:text-2xl font-normal tracking-tight text-zinc-800 m-4 md:m-6">
                  Студия
                </h3>
                <div class="flex justify-center">
                  <img
                    class="max-w-[50%] lg:max-w-[75%] object-cover transition duration-500 object-center"
                    src={Flat}
                  />
                </div>
                <p class="xs:text-xs md:text-sm xl:text-md font-bold text-zinc-800 mt-4 mx-4 md:mt-6 md:mx-6">
                  Индивидуальный проект
                </p>
                <h3 class="xs:text-md md:text-xl xl:text-2xl font-normal mx-4 md:mx-6 tracking-tight text-amber-400">
                  30.000 рублей
                </h3>

                <div className="flex justify-center m-4">
                  <Modal6 />
                </div>
              </div>
            </div>
          </div>

          <div class="flex">
            <div class="flex-auto bg-white rounded-lg shadow-lg">
              <div className="flex flex-col">
                <h3 class="xs:text-md md:text-xl xl:text-2xl font-normal tracking-tight text-zinc-800 m-4 md:m-6">
                  1-к квартира
                </h3>
                <div class="flex justify-center">
                  <img
                    class="max-w-[50%] lg:max-w-[75%] object-cover transition duration-500 object-center"
                    src={Flat1}
                  />
                </div>
                <p class="xs:text-xs md:text-sm xl:text-md font-bold text-zinc-800 mt-4 mx-4 md:mt-6 md:mx-6">
                  Индивидуальный проект
                </p>
                <h3 class="xs:text-md md:text-xl xl:text-2xl font-normal mx-4 md:mx-6 tracking-tight text-amber-400">
                  45.000 рублей
                </h3>
                <div className="flex justify-center m-4">
                  <Modal6 />
                </div>
              </div>
            </div>
          </div>

          <div class="flex">
            <div class="flex-auto bg-white rounded-lg shadow-lg">
              <div className="flex flex-col">
                <h3 class="xs:text-md md:text-xl xl:text-2xl font-normal tracking-tight text-zinc-800 m-4 md:m-6">
                  2-к квартира
                </h3>
                <div class="flex justify-center">
                  <img
                    class="max-w-[50%] lg:max-w-[75%] object-cover transition duration-500 object-center"
                    src={Flat2}
                  />
                </div>
                <p class="xs:text-xs md:text-sm xl:text-md font-bold text-zinc-800 mt-4 mx-4 md:mt-6 md:mx-6">
                  Индивидуальный проект
                </p>
                <h3 class="xs:text-md md:text-xl xl:text-2xl font-normal mx-4 md:mx-6 tracking-tight text-amber-400">
                  60.000 рублей
                </h3>
                <div className="flex justify-center m-4">
                  <Modal6 />
                </div>
              </div>
            </div>
          </div>

          <div class="flex">
            <div class="flex-auto bg-white rounded-lg shadow-lg">
              <div className="flex flex-col">
                <h3 class="xs:text-md md:text-xl xl:text-2xl font-normal tracking-tight text-zinc-800 m-4 md:m-6">
                  3-к квартира
                </h3>
                <div class="flex justify-center">
                  <img
                    class="max-w-[50%] lg:max-w-[75%] object-cover transition duration-500"
                    src={Flat3}
                  />
                </div>
                <p class="xs:text-xs md:text-sm xl:text-md font-bold text-zinc-800 mt-4 mx-4 md:mt-6 md:mx-6">
                  Индивидуальный проект
                </p>
                <h3 class="xs:text-md md:text-xl xl:text-2xl font-normal mx-4 md:mx-6 tracking-tight text-amber-400">
                  75.000 рублей
                </h3>
                <div className="flex justify-center m-4">
                  <Modal6 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
