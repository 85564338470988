import React from "react";
import Interior1 from "../../assets/images/iprojects/iproject1/iproject1-1.webp";
import Modal1 from "../general/imodals/modal1";

const Project1 = () => {
  return (
    <div class="h-full bg-white rounded-lg shadow">
      <a>
        <img
          class="rounded-t-lg object-cover h-48 w-full"
          src={Interior1}
          alt=""
        />
      </a>
      <div class="p-8 realtive">
        <Modal1 />
        <p class="mb-3 text-lg text-gray-700">
          Тропический интерьер позволяет воссоздать атмосферу отпуска в
          загородном доме или в городской квартире. Ключевое здесь -
          натуральность отделки, обилие зелени и соответствующий декор.
        </p>
      </div>
    </div>
  );
};

export default Project1;
