import React from "react";
import { ReactComponent as ReactLogo } from "../../assets/images/logo-white.svg";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Modal2 from "../general/modal2";

export const Navigation = () => {
  return (
    <>
      <div className="w-full fixed z-20 top-10 hidden md:block">
        <nav class="flex justify-between md:max-w-7xl">
          <div className="rounded-lg bg-zinc-800/60 backdrop-blur-sm px-6 py-3">
            <a href="https://lpole.ru">
              <ReactLogo className="h-8 shrink" alt="Lpole logo" />
            </a>
          </div>
          <div className="bg-zinc-800/60 backdrop-blur-sm px-1 text-white rounded-lg">
            <div class="flex items-center justify-between">
              <div
                class="items-center justify-between hidden md:flex shrink"
                id="navbar-sticky"
              >
                <ul class="flex flex-col rounded-lg md:flex-row md:space-x-2 md:mt-0 md:border-0">
                  <li>
                    <a
                      href="#projects"
                      class="block py-2 pl-4 hover:text-amber-400"
                    >
                      Проекты
                    </a>
                  </li>
                  <li>
                    <a
                      href="#footer"
                      class="block py-2 px-4 hover:text-amber-400"
                      aria-current="page"
                    >
                      Контакты
                    </a>
                  </li>
                </ul>
                <div className="flex mr-3">
                  <a
                    href="https://wa.me/+79267208838?text=Здравствуйте, мне нужен дизайн проект квартиры."
                    class="py-2.5 mr-3 relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium rounded-lg group bg-zinc-600 hover:bg-green-500"
                  >
                    <svg
                      fill="#FFFFFF"
                      className="w-5 h-5 ml-2 mr-2"
                      width="100%"
                      height="100%"
                      viewBox="0 0 506.964 506.964"
                    >
                      <g transform="translate(2 1)">
                        <g>
                          <g>
                            <path d="M252.321-1C113.83-1,1.357,111.472,1.357,249.125c0,43.646,11.751,85.613,33.574,124.223L-2,505.964l135.134-36.092c39.449,20.984,80.577,32.734,119.187,32.734c136.813,0,252.643-115.83,252.643-253.482S391.652-1,252.321-1z M252.321,485.82c-36.931,0-76.38-11.751-114.151-32.734l-3.357-1.679L21.502,482.462L52.557,370.83l-1.679-3.357c-20.984-36.092-32.734-77.22-32.734-118.348c0-128.42,104.918-233.338,234.177-233.338c130.098,0,235.856,104.918,235.856,233.338S379.902,485.82,252.321,485.82z" />
                            <path d="M252.321,40.967c-114.99,0-208.997,93.167-208.997,208.157c0,41.128,11.751,79.738,32.734,114.151l3.357,4.197L56.754,447.21l82.256-20.984l4.197,2.518c34.413,20.984,72.184,31.895,109.115,31.895c115.83,0,210.675-94.846,210.675-211.515C462.997,134.134,368.151,40.967,252.321,40.967z M251.482,444.692c-33.574,0-67.987-10.911-99.882-30.216l-10.072-6.715l-61.272,15.108l16.787-58.754l-6.715-10.911c-20.144-31.056-30.216-67.148-30.216-104.079c0-104.918,86.452-191.37,192.21-191.37c106.597,0,193.889,86.452,193.889,191.37C446.21,356.561,358.918,443.852,251.482,444.692z" />
                            <path d="M378.223,295.289l-52.879-24.341c-7.554-3.357-15.108-0.839-20.144,5.036l-20.144,26.02c-15.108-5.875-57.075-24.341-81.416-69.666l18.466-21.823c4.197-5.036,5.036-11.751,2.518-17.626l-22.662-53.718c-2.518-5.875-7.554-10.072-14.269-10.072l-15.948-0.839c-6.715,0-14.269,1.679-19.305,6.715c-7.554,6.715-21.823,20.984-26.02,40.289c-5.875,27.698,3.357,60.433,26.859,92.328c14.269,20.144,57.915,79.738,141.849,103.239c8.393,2.518,16.787,3.357,24.341,3.357c14.269,0,27.698-4.197,39.449-10.911c13.43-8.393,22.662-22.662,26.02-37.771l2.518-11.751C389.134,306.2,384.938,298.646,378.223,295.289z M367.311,321.308c-2.518,10.911-9.233,20.984-18.466,26.859c-14.269,9.233-31.056,10.911-49.521,5.875c-78.059-21.823-119.187-78.059-132.616-96.525c-20.144-27.698-28.538-56.236-23.502-78.898c3.357-15.108,14.269-26.02,20.984-31.895c1.679-1.679,4.197-2.518,6.715-2.518l16.787,1.679l22.662,52.879l-20.144,22.662c-3.357,4.197-4.197,10.911-1.679,15.948c27.698,53.718,78.059,74.702,93.167,79.738c5.875,1.679,11.751,0,15.948-5.036l20.144-26.859l52.879,24.341L367.311,321.308z" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                  <a
                    href="https://t.me/+79267208838/"
                    class="py-2.5 relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium rounded-lg group bg-zinc-600 hover:bg-blue-500"
                  >
                    <svg
                      fill="#FFFFFF"
                      className="w-5 h-5 ml-2 mr-2"
                      width="100%"
                      height="100%"
                      viewBox="0 0 256 256"
                      id="Flat"
                    >
                      <path d="M228.646,34.7676a11.96514,11.96514,0,0,0-12.21778-2.0752L31.87109,105.19729a11.99915,11.99915,0,0,0,2.03467,22.93457L84,138.15139v61.833a11.8137,11.8137,0,0,0,7.40771,11.08593,12.17148,12.17148,0,0,0,4.66846.94434,11.83219,11.83219,0,0,0,8.40918-3.5459l28.59619-28.59619L175.2749,217.003a11.89844,11.89844,0,0,0,7.88819,3.00195,12.112,12.112,0,0,0,3.72265-.59082,11.89762,11.89762,0,0,0,8.01319-8.73925L232.5127,46.542A11.97177,11.97177,0,0,0,228.646,34.7676ZM32.2749,116.71877a3.86572,3.86572,0,0,1,2.522-4.07617L203.97217,46.18044,87.07227,130.60769,35.47461,120.28811A3.86618,3.86618,0,0,1,32.2749,116.71877Zm66.55322,86.09375A3.99976,3.99976,0,0,1,92,199.9844V143.72048l35.064,30.85669ZM224.71484,44.7549,187.10107,208.88772a4.0003,4.0003,0,0,1-6.5415,2.10937l-86.1543-75.8164,129.66309-93.645A3.80732,3.80732,0,0,1,224.71484,44.7549Z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="flex">
                <Modal2 />
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div>
        <nav class="block md:hidden bg-zinc-800/60 backdrop-blur-sm  max-w-xs sm:max-w-sm container text-white fixed left-1/2 -translate-x-1/2 z-20 top-5 rounded-lg">
          <div class="flex items-center justify-between mx-auto p-3">
            <a href="https://lpole.ru" class="shrink">
              <ReactLogo className="h-8 w-full mr-3" alt="Lpole logo" />
            </a>
            <div className="flex">
              <a
                href="https://wa.me/+79267208838?text=Привет, я по поводу VR"
                class="py-2.5 relative inline-flex items-center justify-center p-0.5 ml-3 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-green-500 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400"
              >
                <svg
                  fill="#FFFFFF"
                  className="w-5 h-5 ml-2 mr-2"
                  width="100%"
                  height="100%"
                  viewBox="0 0 506.964 506.964"
                >
                  <g transform="translate(2 1)">
                    <g>
                      <g>
                        <path d="M252.321-1C113.83-1,1.357,111.472,1.357,249.125c0,43.646,11.751,85.613,33.574,124.223L-2,505.964l135.134-36.092c39.449,20.984,80.577,32.734,119.187,32.734c136.813,0,252.643-115.83,252.643-253.482S391.652-1,252.321-1z M252.321,485.82c-36.931,0-76.38-11.751-114.151-32.734l-3.357-1.679L21.502,482.462L52.557,370.83l-1.679-3.357c-20.984-36.092-32.734-77.22-32.734-118.348c0-128.42,104.918-233.338,234.177-233.338c130.098,0,235.856,104.918,235.856,233.338S379.902,485.82,252.321,485.82z" />
                        <path d="M252.321,40.967c-114.99,0-208.997,93.167-208.997,208.157c0,41.128,11.751,79.738,32.734,114.151l3.357,4.197L56.754,447.21l82.256-20.984l4.197,2.518c34.413,20.984,72.184,31.895,109.115,31.895c115.83,0,210.675-94.846,210.675-211.515C462.997,134.134,368.151,40.967,252.321,40.967z M251.482,444.692c-33.574,0-67.987-10.911-99.882-30.216l-10.072-6.715l-61.272,15.108l16.787-58.754l-6.715-10.911c-20.144-31.056-30.216-67.148-30.216-104.079c0-104.918,86.452-191.37,192.21-191.37c106.597,0,193.889,86.452,193.889,191.37C446.21,356.561,358.918,443.852,251.482,444.692z" />
                        <path d="M378.223,295.289l-52.879-24.341c-7.554-3.357-15.108-0.839-20.144,5.036l-20.144,26.02c-15.108-5.875-57.075-24.341-81.416-69.666l18.466-21.823c4.197-5.036,5.036-11.751,2.518-17.626l-22.662-53.718c-2.518-5.875-7.554-10.072-14.269-10.072l-15.948-0.839c-6.715,0-14.269,1.679-19.305,6.715c-7.554,6.715-21.823,20.984-26.02,40.289c-5.875,27.698,3.357,60.433,26.859,92.328c14.269,20.144,57.915,79.738,141.849,103.239c8.393,2.518,16.787,3.357,24.341,3.357c14.269,0,27.698-4.197,39.449-10.911c13.43-8.393,22.662-22.662,26.02-37.771l2.518-11.751C389.134,306.2,384.938,298.646,378.223,295.289z M367.311,321.308c-2.518,10.911-9.233,20.984-18.466,26.859c-14.269,9.233-31.056,10.911-49.521,5.875c-78.059-21.823-119.187-78.059-132.616-96.525c-20.144-27.698-28.538-56.236-23.502-78.898c3.357-15.108,14.269-26.02,20.984-31.895c1.679-1.679,4.197-2.518,6.715-2.518l16.787,1.679l22.662,52.879l-20.144,22.662c-3.357,4.197-4.197,10.911-1.679,15.948c27.698,53.718,78.059,74.702,93.167,79.738c5.875,1.679,11.751,0,15.948-5.036l20.144-26.859l52.879,24.341L367.311,321.308z" />
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
              <a
                href="https://t.me/+79267208838/"
                class="py-2.5 relative inline-flex items-center justify-center p-0.5 ml-3 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-blue-500 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400"
              >
                <svg
                  fill="#FFFFFF"
                  className="w-5 h-5 ml-2 mr-2"
                  width="100%"
                  height="100%"
                  viewBox="0 0 256 256"
                  id="Flat"
                >
                  <path d="M228.646,34.7676a11.96514,11.96514,0,0,0-12.21778-2.0752L31.87109,105.19729a11.99915,11.99915,0,0,0,2.03467,22.93457L84,138.15139v61.833a11.8137,11.8137,0,0,0,7.40771,11.08593,12.17148,12.17148,0,0,0,4.66846.94434,11.83219,11.83219,0,0,0,8.40918-3.5459l28.59619-28.59619L175.2749,217.003a11.89844,11.89844,0,0,0,7.88819,3.00195,12.112,12.112,0,0,0,3.72265-.59082,11.89762,11.89762,0,0,0,8.01319-8.73925L232.5127,46.542A11.97177,11.97177,0,0,0,228.646,34.7676ZM32.2749,116.71877a3.86572,3.86572,0,0,1,2.522-4.07617L203.97217,46.18044,87.07227,130.60769,35.47461,120.28811A3.86618,3.86618,0,0,1,32.2749,116.71877Zm66.55322,86.09375A3.99976,3.99976,0,0,1,92,199.9844V143.72048l35.064,30.85669ZM224.71484,44.7549,187.10107,208.88772a4.0003,4.0003,0,0,1-6.5415,2.10937l-86.1543-75.8164,129.66309-93.645A3.80732,3.80732,0,0,1,224.71484,44.7549Z" />
                </svg>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};
