import React from "react";
import Interior4 from "../../assets/images/iprojects/iproject4/iproject4-1.webp";
import Modal4 from "../general/imodals/modal4";

const Project1 = () => {
  return (
    <div class="h-full bg-white rounded-lg shadow">
      <a>
        <img
          class="rounded-t-lg object-cover h-48 w-full"
          src={Interior4}
          alt=""
        />
      </a>
      <div class="p-8 realtive">
        <Modal4 />
        <p class="mb-3 text-lg text-gray-700 line-clamp-5">
          Белоснежная кухня - вот что первее всего привлекает взгляд в этом
          интерьере. Тут ничего лишнего и даже не хочется ничего добавить - на
          столько органично смотрится фасад.
        </p>
      </div>
    </div>
  );
};

export default Project1;
