import image1 from "../../assets/images/projects/project5/project5-1.webp";
import image2 from "../../assets/images/projects/project5/project5-2.webp";
import image3 from "../../assets/images/projects/project5/project5-3.webp";
import image4 from "../../assets/images/projects/project5/project5-4.webp";
import image5 from "../../assets/images/projects/project5/project5-5.webp";
import image6 from "../../assets/images/projects/project5/project5-6.webp";
import image7 from "../../assets/images/projects/project5/project5-7.webp";

export const images = [image1, image2, image3, image4, image5, image6, image7];

const IndexProject5 = (index) => images[index % images.length];

export default IndexProject5;
