import React from "react";

export const How = () => {
  return (
    <div className="bg-zinc-100 md:py-20 p-10">
      <div className="mx-auto md:max-w-7xl">
        <h2 className="text-3xl md:text-5xl mb-10 md:mb-20 uppercase">
          Как воспользоваться услугой?
        </h2>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 md:gap-4">
            <div class="bg-zinc-700 rounded-lg px-1 py-2 md:px-5 md:py-5 shadow-lg ">
              <div className="flex flex-col">
                <h5 class="text-xl md:text-4xl text-amber-400 mt-3 mx-3 font-normal tracking-tight">
                  <span className="mavka">Шаг 1 </span>
                </h5>
                <p class="text-sm md:text-md lg:text-xl my-3 mx-3 font-normal text-white">
                  Сделайте замеры каждого помещения (высота, ширина, длина),
                  количество и размеры дверных проемов и окон.
                </p>
              </div>
            </div>

            <div class="bg-zinc-700 rounded-lg px-1 py-2 md:px-5 md:py-5 shadow-lg">
              <div className="flex flex-col">
                <h5 class="text-xl md:text-4xl text-amber-400 mt-3 mx-3 font-normal tracking-tight">
                  <span className="mavka">Шаг 2 </span>
                </h5>
                <p class="text-sm md:text-md lg:text-xl my-3 mx-3 font-normal text-white">
                  Выберите один из предложенных нами готовых{" "}
                  <a className="hover:text-zinc-300" href="#projects">
                    дизайн-проектов
                  </a>
                  .
                </p>
              </div>
            </div>

            <div class="bg-zinc-700 rounded-lg px-1 py-2 md:px-5 md:py-5 shadow-lg">
              <div className="flex flex-col">
                <h5 class="text-xl md:text-4xl text-amber-400 mt-3 mx-3 font-normal tracking-tight">
                  <span className="mavka">Шаг 3 </span>
                </h5>
                <p class="text-sm md:text-md lg:text-xl my-3 mx-3 font-normal text-white">
                  Запишитесь на встречу с дизайнером удобным для вас способом.
                </p>
              </div>
            </div>

            <div class="bg-zinc-700 rounded-lg px-1 py-2 md:px-5 md:py-5 shadow-lg">
              <div className="flex flex-col">
                <h5 class="text-xl md:text-4xl text-amber-400 mt-3 mx-3 font-normal tracking-tight">
                  <span className="mavka">Шаг 4 </span>
                </h5>
                <p class="text-sm md:text-md lg:text-xl my-3 mx-3 font-normal text-white">
                  Посетите ТЦ "Люблинское поле" и закройте все вопросы по
                  ремонту за один визит!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
