import image1 from "../../assets/images/projects/project3/project3-1.webp";
import image2 from "../../assets/images/projects/project3/project3-2.webp";
import image3 from "../../assets/images/projects/project3/project3-3.webp";
import image4 from "../../assets/images/projects/project3/project3-4.webp";
import image5 from "../../assets/images/projects/project3/project3-5.webp";
import image6 from "../../assets/images/projects/project3/project3-6.webp";
import image7 from "../../assets/images/projects/project3/project3-7.webp";
import image8 from "../../assets/images/projects/project3/project3-8.webp";

export const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
];

const IndexProject3 = (index) => images[index % images.length];

export default IndexProject3;
