import React from "react";

export const Feedback = () => {
  return (
    <div className="bg-zinc-100 md:py-20 p-10">
      <div className="mx-auto md:max-w-7xl">
        <h2 className="text-3xl md:text-5xl mb-10 md:mb-20 uppercase text-zinc-800">
          Что о нас говорят клиенты
        </h2>
        <div className="flex flex-nowrap gap-4 pb-20 overflow-x-scroll">
          <div class="rounded-lg min-w-fit md:min-w-min h-96 md:h-80 bg-white shadow-lg text-zinc-800 p-8 relative">
            <p className="text-md">
              Выражаю большую благодарность дизайнерам Люблинского поля. Они
              сделали очень классный дизайн-проект и воплотили в жизнь все наши
              хотелки)). Отдельное спасибо Ольге, что всегда была на связи в
              процессе ремонта.
            </p>
            <div className="bottom-8 absolute">
              <div class="flex items-center justify-center gap-3">
                <div>
                  <img
                    class="w-10 h-10 rounded-full"
                    src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=80"
                  />
                </div>
                <div>
                  <p className="text-sm">
                    <b>Ольга К</b>
                  </p>
                  <p className="text-sm text-zinc-500">Дизайн студии 30 м²</p>
                </div>
              </div>
            </div>
          </div>
          <div class="rounded-lg min-w-fit md:min-w-min h-96 md:h-80 bg-white shadow-lg text-zinc-800 p-8 relative">
            <div>
              <p className="text-md">
                Спасибо большое Евгении!!! За помощь в организации и подборке
                мебели в маленькую спальню. Я, сама не смогла бы вместить в
                помещение 9 кв метров стол, шкаф и кровать, так гармонично.
              </p>
            </div>
            <div className="bottom-8 absolute">
              <div class="flex items-center justify-center gap-3">
                <div>
                  <img
                    class="w-10 h-10 rounded-full"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=80"
                  />
                </div>
                <div>
                  <p className="text-sm">
                    <b>Саимат М</b>
                  </p>
                  <p className="text-sm text-zinc-500">Дизайн спальни 9 м²</p>
                </div>
              </div>
            </div>
          </div>
          <div class="rounded-lg min-w-fit md:min-w-min h-96 md:h-80 bg-white shadow-lg text-zinc-800 p-8 relative">
            <p className="text-md">
              Я чисто случайно узнал, что в Люблинском поле есть студия дизайна.
              Дизайнеры помогли определить концепцию дизайна, которую хочу
              именно я. Остался очень доволен сотрудничеством.
            </p>
            <div className="bottom-8 absolute">
              <div class="flex items-center justify-center gap-3">
                <div>
                  <img
                    class="w-10 h-10 rounded-full"
                    src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=80"
                  />
                </div>
                <div>
                  <p className="text-sm">
                    <b>Шамиль Х</b>
                  </p>
                  <p className="text-sm text-zinc-500">
                    Дизайн гостинной 21 м²
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
