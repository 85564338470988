import React from "react";
import { ReactComponent as ReactLogoW } from "../../assets/images/logo-white.svg";
import Logo from "../../assets/images/logo-white.svg";

export const Footer = () => {
  return (
    <footer id="footer" class="bg-zinc-800">
      <div class="mx-auto w-full max-w-screen-xl p-4 pt-4 lg:pt-8">
        <div class="md:flex md:justify-between">
          <div class="mb-6 md:mb-0">
            <a
              href="https://www.lpole.ru/"
              class="flex justify-center sm:items-center pt-2"
            >
              <ReactLogoW className="h-14 w-72" alt="Люблинское поле" />
            </a>
          </div>
          <div class="grid gap-5 grid-cols-2 lg:grid-cols-3 ml-0 md:ml-12 lg:ml-48 mt-12 md:mt-0">
            <div>
              <p class="mb-4 xs:text-sm md:text-md xl:text-lg text-white uppercase">
                Торговый центр
              </p>
              <ul class="text-white">
                <li class="mb-4 xs:text-xs md:text-sm xl:text-md">
                  <p>Телефон:</p>
                  <p>+7 (495) 785-34-34</p>
                </li>
                <li class="mb-4 xs:text-xs md:text-sm xl:text-md">
                  <p>Время работы:</p>
                  <p>Ежедневно: 9:00 - 20:00</p>
                </li>
              </ul>
            </div>
            <div>
              <p class="mb-4 xs:text-sm md:text-md xl:text-lg text-white uppercase">
                Дизайн-студия
              </p>
              <ul class="text-white">
                <li class="mb-4 xs:text-xs md:text-sm xl:text-md">
                  <p>Телефон:</p>
                  <p>+7 (499) 390-10-60</p>
                </li>
                <li class="mb-4 xs:text-xs md:text-sm xl:text-md">
                  <p>Время работы:</p>
                  <p>ПН-ПТ: 9:00 - 20:00</p>
                  <p>СБ-ВС: 12:00 - 20:00</p>
                </li>
              </ul>
            </div>

            <div className="col-span-2 sm:col-span-1">
              <p class="mb-4 xs:text-sm md:text-md xl:text-lg text-white uppercase">
                Адрес
              </p>
              <ul class="text-white">
                <li class="mb-4 xs:text-xs md:text-sm xl:text-md">
                  <p>г. Москва, Тихорецкий б-р. 1, стр. 2а, (м. Люблино)</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="my-3 border-zinc-100 sm:mx-auto lg:my-3" />
        <div class="sm:flex sm:items-center sm:justify-between">
          <span class="text-sm text-zinc-100 sm:text-center">© 2023</span>
        </div>
      </div>
    </footer>
  );
};
