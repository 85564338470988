import React from "react";
import HeroImage from "../../assets/images/Hero2.webp";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import { Link } from "react-router-dom";

export const Ihero = () => {
  return (
    <div>
      <div className="hidden md:block">
        <div class="flex flex-row rounded-lg bg-amber-400 m-3 text-black">
          <div class="basis-2/4 pl-24 pr-24 pt-48">
            <div class="pb-16">
              <h1 class="text-2xl md:text-5xl mb-16 uppercase">
                Индивидуальный дизайн-проект по{" "}
                <span className="mavka">фиксированной </span>цене
              </h1>
              <Link to="/">
                <button
                  type="button"
                  class="mr-5 bg-gray-100 hover:bg-gray-300 hover:italic  focus:outline-none  rounded-lg pl-4 pr-2.5 py-2.5 text-center inline-flex items-center"
                >
                  Вернуться к готовому дизайну
                  <ChevronLeftIcon className="w-8 h-8 ml-2 p-2 rounded" />
                </button>
              </Link>
              <Link to="#iabout">
                <button
                  type="button"
                  class="mr-2 bg-zinc-800 hover:bg-zinc-700 text-white hover:italic  focus:outline-none rounded-lg pl-4 pr-2.5 py-2.5 text-center inline-flex items-center"
                >
                  Заказать консультацию
                  <ChevronDownIcon className="w-8 h-8 ml-2 p-2 rounded" />
                </button>
              </Link>
            </div>
          </div>
          <div class="basis-2/4">
            <img
              src={HeroImage}
              class="h-full rounded-r-lg object-cover"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="block md:hidden flex flex-row rounded-lg bg-amber-400 text-black m-3">
        <div class="px-6 pt-56">
          <div class="pb-16">
            <h1 class="text-2xl mb-16 uppercase">
              Индивидуальный дизайн-проект по{" "}
              <span className="mavka">фиксированной </span>цене
            </h1>

            <a href="/#about">
              <button
                type="button"
                class="mb-4 bg-gray-100 hover:bg-gray-200 hover:italic focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg pl-4 pr-2.5 py-2.5 text-center inline-flex items-center"
              >
                Готовый дизайн
                <ChevronLeftIcon className="w-8 h-8 ml-2 p-2 rounded" />
              </button>
            </a>
            <a href="#iabout">
              <button
                type="button"
                class="mb-4 mr-2 bg-zinc-800 hover:bg-zinc-700 text-white hover:italic focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg pl-4 pr-2.5 py-2.5 text-center inline-flex items-center"
              >
                Заказать консультацию
                <ChevronDownIcon className="w-8 h-8 ml-2 p-2 rounded" />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
