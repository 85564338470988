import React, { useState } from "react";

import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

import Iproject1 from "../iprojects/iproject1";
import Iproject2 from "../iprojects/iproject2";
import Iproject3 from "../iprojects/iproject3";
import Iproject4 from "../iprojects/iproject4";
import Iproject5 from "../iprojects/iproject5";
import Iproject6 from "../iprojects/iproject6";
import Iproject7 from "../iprojects/iproject7";
import Iproject8 from "../iprojects/iproject8";
import ProjectInfo from "../projects/projectinfo";

const Projects2 = () => {
  const [currentComponentArrayIndex, setCurrentPhotoIndex] = useState(0);

  const handlePrevClick = () => {
    if (currentComponentArrayIndex > 0) {
      setCurrentPhotoIndex(currentComponentArrayIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentComponentArrayIndex < componentArray.length - 1) {
      setCurrentPhotoIndex(currentComponentArrayIndex + 1);
    }
  };

  const componentArray = [
    <>
      <Iproject1 />
      <Iproject2 />
      <Iproject3 />
    </>,
    <>
      <Iproject4 />
      <Iproject5 />
      <Iproject6 />
    </>,
  ];

  return (
    <div id="projects" className="hidden md:block bg-zinc-700 md:py-20 p-5">
      <div className="mx-auto md:max-w-7xl">
        <h2 className="text-3xl md:text-5xl mb-10 md:mb-20 uppercase text-white">
          Реализованные проекты
        </h2>
        <div className="relative flex">
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4 z-10">
            {componentArray[currentComponentArrayIndex]}
          </div>
          <div className="flex justify-between w-full h-full items-center absolute -ml-5">
            <button
              onClick={handlePrevClick}
              class="p-2.5 z-20 relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium rounded-lg group shadow bg-amber-400 hover:bg-zinc-200"
            >
              <ChevronLeftIcon className="w-5 h-5 rounded" />
            </button>
            <button
              onClick={handleNextClick}
              class="-mr-10 z-20 p-2.5 relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium rounded-lg group shadow bg-amber-400 hover:bg-zinc-200"
            >
              <ChevronRightIcon className="w-5 h-5 rounded" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects2;
