import React from "react";
import Interior2 from "../../assets/images/projects/project2/project2-1.webp";
import Modal2 from "../general/modals/modal2";

const Project2 = () => {
  return (
    <div class="h-full bg-white rounded-lg shadow">
      <a>
        <img
          class="rounded-t-lg object-cover h-48 w-full"
          src={Interior2}
          alt=""
        />
      </a>
      <div class="p-8 realtive">
        <Modal2 />
        <p class="mb-3 text-lg text-gray-700 line-clamp-5">
          Этот интерьер подойдет для тех, кто ценит минимализм. В спальне
          сочетаются строгие линии каркаса кровати и замысловатый силуэт люстры.
          Ничего не отвлекает внимания от этой гармонии.
        </p>
      </div>
    </div>
  );
};

export default Project2;
