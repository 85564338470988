import React from "react";
import Interior1 from "../../assets/images/iprojects/iproject6/iproject6-1.webp";
import Modal6 from "../general/imodals/modal6";

const Project1 = () => {
  return (
    <div class="h-full bg-white rounded-lg shadow">
      <a>
        <img
          class="rounded-t-lg object-cover h-48 w-full"
          src={Interior1}
          alt=""
        />
      </a>
      <div class="p-8 realtive">
        <Modal6 />
        <p class="mb-3 text-lg text-gray-700 line-clamp-5">
          Этот интерьер для ценителей спокойного интерьера, не отвлекающего
          внимания. Здесь можно просто расслабиться и погрузиться в свои мысли.
          В спальне - темно-серая стена и затемненные стекла в гардеробной.
        </p>
      </div>
    </div>
  );
};

export default Project1;
