import React from "react";

export const Ifeatures = () => {
  return (
    <div className="custom-bg-1 bg-cover md:py-20 p-10">
      <div className="mx-auto md:max-w-7xl">
        <h2 className="text-3xl md:text-5xl mb-10 md:mb-20 uppercase text-white">
          Для чего нужен дизайн-проект?
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div class="bg-white rounded-lg shadow-lg">
            <div className="flex flex-col">
              <h5 class="xs:text-md md:text-xl xl:text-2xl font-normal tracking-tight text-gray-900 m-4 md:m-6">
                Экономия
              </h5>
              <p class="xs:text-sm md:text-md xl:text-xl font-normal text-gray-900 mx-4 mb-4 md:mx-6 md:mb-6">
                Надежные технические решения и использование качественных
                материалов поможет вам в будущем сэкономить на мелких ремонтных
                работах и реставрациях.{" "}
              </p>
            </div>
          </div>
          <div class="bg-white rounded-lg shadow-lg">
            <div className="flex flex-col">
              <h5 class="xs:text-md md:text-xl xl:text-2xl font-normal tracking-tight text-gray-900 m-4 md:m-6">
                Уменьшение рисков
              </h5>
              <p class="xs:text-sm md:text-md xl:text-xl font-normal text-gray-900 mx-4 mb-4 md:mx-6 md:mb-6">
                Разработанные дизайнером чертержи и расчеты помогут избежать
                ошибок, часто возникающих при ремонте.
              </p>
            </div>
          </div>

          <div class="bg-white rounded-lg shadow-lg">
            <div className="flex flex-col">
              <h5 class="xs:text-md md:text-xl xl:text-2xl font-normal tracking-tight text-gray-900 m-4 md:m-6">
                Современный подход
              </h5>
              <p class="xs:text-sm md:text-md xl:text-xl font-normal text-gray-900 mx-4 mb-4 md:mx-6 md:mb-6">
                Работа с дизайнером дает возможность ознакомиться с тенденциями
                дизайна и новинками материалов, мебели и декора и
                сориентироваться, что из этого подойдет именно вам.{" "}
              </p>
            </div>
          </div>

          <div class="bg-white rounded-lg shadow-lg">
            <div className="flex flex-col">
              <h5 class="xs:text-md md:text-xl xl:text-2xl font-normal tracking-tight text-gray-900 m-4 md:m-6">
                Наглядность{" "}
              </h5>
              <p class="xs:text-sm md:text-md xl:text-xl font-normal text-gray-900  mx-4 mb-4 md:mx-6 md:mb-6">
                Наличие дизайн-проекта помогает представить будущий интерьер до
                его реализации.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
