import React from "react";
import Interior3 from "../../assets/images/projects/project3/project3-1.webp";
import Modal3 from "../general/modals/modal3";

const Project3 = () => {
  return (
    <div class="h-full bg-white rounded-lg shadow">
      <a>
        <img
          class="rounded-t-lg object-cover h-48 w-full"
          src={Interior3}
          alt=""
        />
      </a>
      <div class="p-8 realtive">
        <Modal3 />
        <p class="mb-3 text-lg text-gray-700 line-clamp-5">
          В этом проекте зеленый цвет - доминирующий в нтерьере. На его фоне
          гормонично смотрится декор из светлого дерева. Это прекрасное место
          для отдыха и релаксации в городской квартире. В этом интерьере как
          нельзя лучше будут смотреться живые цветы.
        </p>
      </div>
    </div>
  );
};

export default Project3;
