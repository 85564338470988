import React from "react";
import Interior1 from "../../assets/images/iprojects/iproject5/iproject5-1.webp";
import Modal5 from "../general/imodals/modal5";

const Project1 = () => {
  return (
    <div class="h-full bg-white rounded-lg shadow">
      <a>
        <img
          class="rounded-t-lg object-cover h-48 w-full"
          src={Interior1}
          alt=""
        />
      </a>
      <div class="p-8 realtive">
        <Modal5 />
        <p class="mb-3 text-lg text-gray-700 line-clamp-5">
          Смотря этот проект, проникаешься теплыми ощущениями. В спальне панно с
          изображением морских волн. Изображение сразу мысленно перемещает нас к
          воспоминаниям о теплом море.
        </p>
      </div>
    </div>
  );
};

export default Project1;
