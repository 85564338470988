import React from "react";
import Interior1 from "../../assets/images/iprojects/iproject8/iproject8-1.webp";
import Modal8 from "../general/imodals/modal8";

const Project8 = () => {
  return (
    <div class="h-full bg-white rounded-lg shadow">
      <a>
        <img
          class="rounded-t-lg object-cover h-48 w-full"
          src={Interior1}
          alt=""
        />
      </a>
      <div class="p-8 realtive">
        <Modal8 />
        <p class="mb-3 text-lg text-gray-700 line-clamp-5">
          Название проекта говорит само за себя. Преобладаение белого цвета,
          прямые линии, понятные формы. Ничего не отвлекает ваш взгляд, но при
          этом сложно оторваться от созерцания этой гармонии.
        </p>
      </div>
    </div>
  );
};

export default Project8;
