const CheckboxField = (props) => {
  const { handleChange, label, name, type, value } = props;
  return (
    <div className="mb-5">
      <input
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        type="checkbox"
        onChange={handleChange}
        value={value}
        name={name}
        required
      />
      <label for="link-checkbox" class="ml-2 text-sm font-medium text-gray-900">
        Я согласен с
        <a href="#" class="text-blue-600 dark:text-blue-500 hover:underline">
          {" "}
          политикой конфиденциальности
        </a>
        .
      </label>
    </div>
  );
};

export default CheckboxField;
