import React from "react";
import Modal3 from "../general/modal3";

const ProjectInfo = () => {
  return (
    <div class="h-full bg-amber-400 rounded-lg shadow md:relative">
      <div class="p-5 md:p-10">
        <a href="#" class="shrink">
          <h5 class="mb-3 text-2xl text-gray-900">
            Хотите пообщаться на тему вашего проекта?
          </h5>
        </a>
        <p class="mb-3 text-lg text-gray-700">
          Подберем и рассчитаем все отделочные материалы. Оформим заказ,
          доставку и сопутствующие услуги.
        </p>
        <div className="bottom-8 right-8 md:absolute">
          <Modal3 />
        </div>
      </div>
    </div>
  );
};

export default ProjectInfo;
