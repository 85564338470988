import React from "react";
import HeroImage from "../../assets/images/Hero.webp";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

import { Link } from "react-router-dom";

export const Hero = () => {
  return (
    <div>
      <div className="hidden md:block">
        <div class="flex flex-cols-1 rounded-lg bg-zinc-800 m-3">
          <div class="basis-2/4 pl-24 pr-24 pt-48">
            <div class="pb-16">
              <div className="relative">
                <h1 class="text-4xl lg:text-5xl text-white uppercase">
                  Готовые интерьеры{" "}
                  <p>
                    для вашего дома{" "}
                    <span className="text-amber-400">бесплатно</span>
                  </p>
                </h1>
                <p className="hidden mavka text-2xl text-white bottom-0 right-5 -rotate-6 absolute">
                  Бесплатно для покупателей <p>нашего торгового центра</p>{" "}
                </p>
              </div>

              <p class="text-gray-200 mb-16 mt-5 text-lg lg:max-w-lg">
                Мы разработали готовые интерьеры в популярных стилях и учли все
                детали для комфортной жизни. Мы докажем вам, что ремонт может
                быть приятным занятием!
              </p>
              <a href="#projects">
                <button
                  type="button"
                  class="mr-5 bg-gray-100 hover:bg-gray-300 hover:italic  focus:outline-none  rounded-lg pl-4 pr-2.5 py-2.5 text-center inline-flex items-center">
                  Посмотреть все проекты
                  <ChevronRightIcon className="w-8 h-8 ml-2 p-2 rounded" />
                </button>
              </a>
              <Link to="id">
                <button
                  type="button"
                  class="mr-4 my-4 bg-amber-400 hover:bg-amber-300 hover:italic  focus:outline-none  rounded-lg pl-4 pr-2.5 py-2.5 text-center inline-flex items-center">
                  Индивидуальный дизайн
                  <ChevronRightIcon className="w-8 h-8 ml-2 p-2 rounded" />
                </button>
              </Link>
            </div>
          </div>
          <div class="basis-2/4">
            <img
              src={HeroImage}
              class="h-full rounded-r-lg object-cover brightness-50"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="block md:hidden flex flex-row rounded-lg bg-zinc-800 m-3">
        <div class="px-6 pt-40">
          <div class="pb-16">
            <h1 class="text-2xl text-white mb-16 uppercase">
              <span className="text-amber-400 text-4xl">Готовые</span>{" "}
              <span className="text-4xl">интерьеры для вашего дома</span>
            </h1>
            <p class="hidden text-gray-200 mb-16 text-lg ">
              Мы разработали готовые интерьеры в популярных стилях и учли все
              детали для комфортной жизни. Мы докажем вам, что ремонт может быть
              приятным занятием!
            </p>

            <a href="/id">
              <button
                type="button"
                class="w-64 mb-4 bg-amber-400 hover:bg-amber-300 hover:italic  focus:outline-none rounded-lg pl-4 pr-2.5 py-2.5 text-center inline-flex items-center">
                Индивидуальный дизайн
                <ChevronRightIcon className="w-8 h-8 ml-2 p-2 rounded" />
              </button>
            </a>
            <a href="#projects">
              <button
                type="button"
                class="w-64 mr-4 my-2 bg-gray-100 hover:bg-gray-200 hover:italic focus:outline-none rounded-lg pl-4 pr-2.5 py-2.5 text-center inline-flex items-center">
                Посмотреть все проекты
                <ChevronRightIcon className="w-8 h-8 ml-2 p-2 rounded" />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
