import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

import EmblaCarousel3 from "../icaroucels/EmblaCarousel3";

import "../caroucels/embla.css";
import "../caroucels/sandbox.css";

import CaroucelModal from "../caroucelmodal";

const OPTIONS = {};
const SLIDE_COUNT = 7;
const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

export default function Modal3() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal3() {
    setIsOpen(false);
  }

  function openModal3() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="flex items-center mb-4">
        <h5
          onClick={openModal3}
          class="text-2xl text-zinc-800 hover:text-zinc-500 cursor-pointer mr-4"
        >
          Лофт
        </h5>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal3}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Dialog.Panel className="w-full max-w-7xl transform overflow-hidden rounded-lg bg-white/90 backdrop-blur-lg p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title>
                  <div class="flex justify-between items-center mb-4 px-2 md:px-4">
                    <h3 class="xs:text-xl md:text-2xl xl:text-3xl uppercase">
                      Лофт
                    </h3>
                    <button
                      type="button"
                      onClick={closeModal3}
                      class="focus:outline-none hover:scale-110 transform duration-1000"
                      data-modal-toggle="defaultModal"
                    >
                      <svg
                        aria-hidden="true"
                        class="w-10 h-10 p-2 -mr-3 md:-mr-5 rounded"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span class="sr-only">Закрыть окно</span>
                    </button>
                  </div>
                </Dialog.Title>
                <div className="grid grid-cols-1 md:grid-cols-3">
                  <div className="sandbox md:col-span-2 px-2 md:px-4">
                    <section className="sandbox__carousel">
                      <EmblaCarousel3 slides={SLIDES} options={OPTIONS} />
                    </section>
                  </div>
                  <div className="px-2 md:px-4 pt-8 md:pt-0">
                    <p class="text-lg md:text-lg hidden md:block">
                      Заказчик проекта хотел интерьер в морской тематике, но при
                      этом, спальня для дочери должна была быть более нежная.
                      Получилось шикарное сочетание оттенков в одном проекте. В
                      одной из спален в изголовье кровати - панно во всю стену с
                      разбивающимися о скалы волнами. Это придает динамики
                      светлому интерьеру. Отдельное удовольствие - гостиная.
                      Здесь удобные стулья с бархатной обивкой в глубоко-синем
                      цвете. Стена за диваном выкрашена в синий цвет - хорошо
                      оттеняет светлую обивку.
                    </p>
                    <p class="text-lg md:hidden">
                      Заказчик проекта хотел интерьер в морской тематике, но при
                      этом, спальня для дочери должна была быть более нежная.
                      Получилось шикарное сочетание оттенков в одном проекте.
                    </p>
                    <div className="mt-5">
                      <CaroucelModal />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
