import image1 from "../../assets/images/projects/project1/project1-1.webp";
import image2 from "../../assets/images/projects/project1/project1-2.webp";
import image3 from "../../assets/images/projects/project1/project1-3.webp";
import image4 from "../../assets/images/projects/project1/project1-4.webp";
import image5 from "../../assets/images/projects/project1/project1-5.webp";
import image6 from "../../assets/images/projects/project1/project1-6.webp";

export const images = [image1, image2, image3, image4, image5, image6];

const IndexProject1 = (index) => images[index % images.length];

export default IndexProject1;
