import React from "react";
import Interior4 from "../../assets/images/projects/project4/project4-4.webp";
import Modal4 from "../general/modals/modal4";

const Project1 = () => {
  return (
    <div class="h-full bg-white rounded-lg shadow">
      <a>
        <img
          class="rounded-t-lg object-cover h-48 w-full"
          src={Interior4}
          alt=""
        />
      </a>
      <div class="p-8 realtive">
        <Modal4 />
        <p class="mb-3 text-lg text-gray-700 line-clamp-5">
          Интерьер в светлых оттенках всегда смотрится изящно и дорого. Лепнина
          из современных искусственных материалов придает роскоши и объема. А
          стильные стальные детали люстр, стульев и фурнитура оживляют дизайн.
        </p>
      </div>
    </div>
  );
};

export default Project1;
