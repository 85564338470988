import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import InputField from "./form/InputField";
import TelephoneField from "./form/TelephoneField";
import CheckboxField from "./form/CheckboxField";

import SuccessModal from "./form/SuccessModal";

const CallBack = () => {
  const [values, setValues] = useState({
    user: "",
    tel: "",
    agreement: "",
    email: "",
    role: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const [isActive, setIsActive] = useState(false);

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send("service_oufdqkw", "template_6axq3jw", values, "OxOKaM4VoT-_hEl59")
      .then(
        (response) => {
          console.log("SUCCESS!", response);
          setValues({
            user: "",
            tel: "",
            agreement: "",
            email: "",
            role: "",
            message: "",
          });
          setStatus("SUCCESS");
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  useEffect(() => {
    if (status === "SUCCESS") {
      setTimeout(() => {
        setStatus("");
      }, 20000);
    }
  }, [status]);

  const handleChange = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <div>
      {status && renderAlert()}
      <form onSubmit={handleSubmit}>
        <InputField
          value={values.user}
          handleChange={handleChange}
          label="Ваше имя"
          name="user"
          type="text"
          placeholder="Как к вам обращаться"
        />
        <TelephoneField
          value={values.tel}
          handleChange={handleChange}
          label="Номер телефона"
          name="tel"
          type="text"
          placeholder="000-000-0000"
        />
        <CheckboxField
          value={values.agreement}
          handleChange={handleChange}
          label="Номер телефона"
          name="agreement"
          type="text"
          placeholder="000-000-0000"
        />
        <button
          onClick={handleClick}
          type="submit"
          class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150"
        >
          <svg
            className={
              isActive
                ? "animate-spin five -ml-1 mr-3 h-5 w-5 text-white"
                : "hidden -ml-1 mr-3 h-5 w-5 text-white"
            }
            class="-ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Отправить
        </button>
      </form>
    </div>
  );
};

const renderAlert = () => <SuccessModal />;

export default CallBack;
