import React from "react";
import Modal5 from "../general/modal5";

export const Iservice = () => {
  return (
    <div className="bg-white md:py-20 p-10">
      <div className="mx-auto md:max-w-7xl">
        <h2 className="text-3xl md:text-5xl mb-10 md:mb-20 uppercase text-zinc-800">
          Все услуги дизайн-студии
        </h2>
        <div className="grid grid-cols-1 md:grid-rows-2 md:grid-cols-3 gap-4">
          <div class="rounded-lg bg-zinc-700 text-white ">
            <h3 className="xs:text-md md:text-xl xl:text-2xl font-normal tracking-tight text-white m-4 md:m-6">
              {" "}
              Авторский надзор
            </h3>
            <span class="xs:text-sm md:text-md xl:text-xl font-medium px-3 py-1 md:px-4 mx-4 md:mx-6 md:mb-6 rounded-2xl bg-zinc-600 text-zinc-300">
              от 20 000 руб.
            </span>

            <p className="xs:text-sm md:text-md xl:text-xl font-normal text-white m-4 md:mx-6 md:mb-6">
              В процессе ремонта контролируем, чтобы рабочие выполняли все в
              соответствии с дизайнерским проектом.
            </p>
          </div>

          <div class="rounded-lg bg-zinc-700 text-white">
            <h3 className="xs:text-md md:text-xl xl:text-2xl font-normal tracking-tight text-white m-4 md:m-6">
              {" "}
              Комплектация ремонта
            </h3>
            <span class="xs:text-sm md:text-md xl:text-xl font-medium px-3 py-1 md:px-4 mx-4 md:mx-6 md:mb-6 rounded-2xl bg-zinc-600 text-zinc-300">
              от 10 000 руб.
            </span>
            <p className="xs:text-sm md:text-md xl:text-xl font-normal text-white m-4 md:mx-6 md:mb-6">
              Берем на себя вопросы по заказу отделочных материалов и мебели.
              Строго контролируем доставку заказов на объект и комплектность.
            </p>
          </div>

          <div class="relative row-span-2 rounded-lg bg-zinc-700 text-white ">
            <h3 className="xs:text-md md:text-xl xl:text-2xl font-normal tracking-tight text-white m-4 md:m-6">
              {" "}
              Сопровождение при покупках
            </h3>
            <span class="xs:text-sm md:text-md xl:text-xl font-medium px-3 py-1 md:px-4 mx-4 md:mx-6 md:mb-6 rounded-2xl bg-green-800 text-green-400">
              БЕСПЛАТНО
            </span>
            <p className="xs:text-sm md:text-md xl:text-xl font-normal text-white m-4 md:mx-6 md:mb-6">
              Все покупатели нашего торгового центра могут бесплатно
              воспользоваться услугой сопровождения дизайнера. Мы помогаем
              правильно выбрать товары, исходя из ваших целей, предпочтений и
              бюджета.
            </p>

            <div className="md:bottom-8 md:right-8 mt-4 md:absolute">
              <Modal5 />
            </div>
          </div>

          <div class="rounded-lg bg-zinc-700 text-white ">
            <h3 className="xs:text-md md:text-xl xl:text-2xl font-normal tracking-tight text-white m-4 md:m-6">
              {" "}
              Строительная бригада
            </h3>
            <span class="xs:text-sm md:text-md xl:text-xl font-medium px-3 py-1 md:px-4 mx-4 md:mx-6 md:mb-6 rounded-2xl bg-zinc-600 text-zinc-300">
              от 13 000 за м²
            </span>

            <p className="xs:text-sm md:text-md xl:text-xl font-normal text-white m-4 md:mx-6 md:mb-6">
              Подберем профессиональную бригаду под ваши отделочные работы.
            </p>
          </div>

          <div class="rounded-lg bg-zinc-700 text-white ">
            <h3 className="xs:text-md md:text-xl xl:text-2xl font-normal tracking-tight text-white m-4 md:m-6">
              {" "}
              Технический надзор
            </h3>
            <span class="xs:text-sm md:text-md xl:text-xl font-medium px-3 py-1 md:px-4 mx-4 md:mx-6 md:mb-6 rounded-2xl bg-zinc-600 text-zinc-300">
              от 30 000 руб.
            </span>

            <p className="xs:text-sm md:text-md xl:text-xl font-normal text-white m-4 md:mx-6 md:mb-6">
              Держим под строгим контролем выполнение отделочных работ в
              процессе ремонта.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
