import React from "react";
import { Disclosure, Transition } from "@headlessui/react";

import { PlusIcon } from "@heroicons/react/20/solid";

export const FAQ = () => {
  return (
    <div className="m-3 bg-amber-400 rounded-lg">
      <article className="mx-auto md:max-w-7xl text-zinc-800 py-10 md:py-20">
        <div className="px-10">
          <h2 className="text-3xl md:text-5xl mb-10 md:mb-20 uppercase text-zinc-800">
            Часто задаваемые вопросы
          </h2>
          <div className="mx-auto w-full rounded-2xl bg-amber-400">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex items-center justify-between w-full py-5 font-medium text-2xl text-left border-b border-zinc-800">
                    <span>Что будет после того, как я выберу проект?</span>
                    <PlusIcon
                      className={`${
                        open
                          ? "rotate-90 transform duration-700"
                          : "duration-700"
                      } h-5 w-5`}
                    />
                  </Disclosure.Button>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  ></Transition>
                  <Disclosure.Panel className="py-5 border-b border-zinc-800 text-zinc-800 ">
                    Если вам понравился один из проектов,то наши дизайнеры
                    адаптируют его под ваши размеры и уточнят смету для вашего
                    помещения.
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex items-center justify-between w-full py-5 font-medium text-2xl text-left border-b border-zinc-800">
                    <span>Как мне реализовать проект?</span>
                    <PlusIcon
                      className={`${
                        open
                          ? "rotate-90 transform duration-700"
                          : "duration-700"
                      } h-5 w-5`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="py-5 border-b border-zinc-800 text-zinc-800 ">
                    После адаптации интерьера дизайнер поможет купить все
                    необходимые материалы и выдаст вам папку с вашим проектом. У
                    вас будут будут фотореалистичные визуализации и основные
                    чертежи, которые вы можете передать строителям для
                    реализации проекта
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex items-center justify-between w-full py-5 font-medium text-2xl text-left border-b border-zinc-800">
                    <span>Сколько будут стоить услуги дизайнера?</span>
                    <PlusIcon
                      className={`${
                        open
                          ? "rotate-90 transform duration-700"
                          : "duration-700"
                      } h-5 w-5`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="py-5 border-b border-zinc-800 text-zinc-800 ">
                    Готовый интерьер, составление сметы и общение с дизайнером -
                    абсолютно бесплатная услуга. Вы платите только за товары в
                    нашем торговом центре.
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex items-center justify-between w-full py-5 font-medium text-2xl text-left border-b border-zinc-800">
                    <span>Как заказать индивидуальный дизайн-проект?</span>
                    <PlusIcon
                      className={`${
                        open
                          ? "rotate-90 transform duration-700"
                          : "duration-700"
                      } h-5 w-5`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="py-5 border-b border-zinc-800 text-zinc-800 ">
                    Если вам не подошел ни один из проектов, наши дизайнеры
                    разработают для вас уникальный проект, учитывая все ваши
                    пожелания. Позвоните нам или напишите в WhatsApp, контакты
                    внизу страницы.
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex items-center justify-between w-full py-5 font-medium text-2xl text-left border-b border-zinc-800">
                    <span>
                      Что, если меня не устроит стоимость реализации проекта?
                    </span>
                    <PlusIcon
                      className={`${
                        open
                          ? "rotate-90 transform duration-700"
                          : "duration-700"
                      } h-5 w-5`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="py-5 border-b border-zinc-800 text-zinc-800 ">
                    Мы скорректируем смету под комфортный для Вас бюджет.
                    Дизайнер поможет подобрать альтернативные материалы по
                    вашему запрос
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      </article>
    </div>
  );
};
