import image1 from "../../assets/images/projects/project4/project4-1.webp";
import image2 from "../../assets/images/projects/project4/project4-2.webp";
import image3 from "../../assets/images/projects/project4/project4-3.webp";
import image4 from "../../assets/images/projects/project4/project4-4.webp";
import image5 from "../../assets/images/projects/project4/project4-5.webp";
import image6 from "../../assets/images/projects/project4/project4-6.webp";
import image7 from "../../assets/images/projects/project4/project4-7.webp";

export const images = [image1, image2, image3, image4, image5, image6, image7];

const IndexProject4 = (index) => images[index % images.length];

export default IndexProject4;
