import React from "react";

export const Ihow = () => {
  return (
    <div className="bg-white md:py-20 p-8">
      <div className="mx-auto md:max-w-7xl">
        <h2 className="text-3xl md:text-5xl mb-10 md:mb-20 uppercase text-zinc-800">
          Как мы работаем
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div class="rounded-lg bg-zinc-700 text-white shadow-md">
            <h5 class="xs:text-lg md:text-xl xl:text-2xl text-amber-400 m-4 md:mx-6 font-normal tracking-tight">
              <span className="mavka">1 этап</span>
            </h5>
            <h3 className="xs:text-md md:text-xl font-normal tracking-tight text-white m-4 md:mx-6">
              Заявка
            </h3>
            <p className="xs:text-sm md:text-md font-normal text-white  mx-4 mb-4 md:mx-6">
              Оставьте заявку на нашем сайте и получите бесплатную консультацию
              дизайнера. Мы обсудим ваши пожелания и согласуем выезд на замеры.
            </p>
          </div>

          <div class="rounded-lg bg-zinc-700 text-white shadow-md">
            <h5 class="xs:text-lg md:text-xl xl:text-2xl text-amber-400 m-4 md:mx-6 font-normal tracking-tight">
              <span className="mavka">2 этап</span>
            </h5>
            <h3 className="xs:text-md md:text-xl font-normal tracking-tight text-white m-4 md:mx-6">
              Замеры, заключение договора
            </h3>
            <p className="xs:text-sm md:text-md font-normal text-white  mx-4 mb-4 md:mx-6">
              Наш специалист снимет все необходимые замеры вашего помещения.
              Составит подробное техническое задание на разработку проекта.
            </p>
          </div>

          <div class="rounded-lg bg-zinc-700 text-white shadow-md">
            <h5 class="xs:text-lg md:text-xl xl:text-2xl text-amber-400 m-4 md:mx-6 font-normal tracking-tight">
              <span className="mavka">3 этап</span>
            </h5>
            <h3 className="xs:text-md md:text-xl font-normal tracking-tight text-white m-4 md:mx-6">
              Планировочное решение
            </h3>
            <p className="xs:text-sm md:text-md font-normal text-white mx-4 mb-4 md:mx-6">
              Мы разрабатоем несколько вариантов планировочного решения, чтобы
              вы могли выбрать комфортный для себя вариант.
            </p>
          </div>

          <div class="rounded-lg bg-zinc-700 text-white shadow-md">
            <h5 class="xs:text-lg md:text-xl xl:text-2xl text-amber-400 m-4 md:mx-6 font-normal tracking-tight">
              <span className="mavka">4 этап</span>
            </h5>
            <h3 className="xs:text-md md:text-xl font-normal tracking-tight text-white m-4 md:mx-6">
              Разработка концепции и сметы
            </h3>
            <p className="xs:text-sm md:text-md font-normal text-white mx-4 mb-4 md:mx-6">
              Приступаем к разработке концепции интерьера. Подбираем мебель,
              цвета и текстуры. Формируем смету, корректируем ее под комфортный
              для вас бюджет.
            </p>
          </div>

          <div class="rounded-lg bg-zinc-700 text-white shadow-md">
            <h5 class="xs:text-lg md:text-xl xl:text-2xl text-amber-400 m-4 md:mx-6 font-normal tracking-tight">
              <span className="mavka">5 этап</span>
            </h5>
            <h3 className="xs:text-md md:text-xl font-normal tracking-tight text-white m-4 md:mx-6">
              Визуализация
            </h3>
            <p className="xs:text-sm md:text-md font-normal text-white mx-4 mb-4 md:mx-6">
              Фотореалистичная визуализация и прогулка в VR-очках позволит вам
              не просто увидеть на картинке дизайнерские решения, а полостью
              погрузиться в проект, пройтись по вашей квартире и оценить ее
              пространство.
            </p>
          </div>

          <div class="rounded-lg bg-zinc-700 text-white shadow-md">
            <h5 class="xs:text-lg md:text-xl xl:text-2xl text-amber-400 m-4 md:mx-6 font-normal tracking-tight">
              <span className="mavka">6 этап</span>
            </h5>
            <h3 className="xs:text-md md:text-xl  font-normal tracking-tight text-white m-4 md:mx-6">
              Альбом чертежей и спецификация
            </h3>
            <p className="xs:text-sm md:text-md font-normal text-white mx-4 mb-4 md:mx-6">
              Включает в себя все необходимые чертежи для ремонта. Вам останется
              лишь передать их своей строительной бригаде. А по готовой
              спецификации вы сможете легко купить все товары для реализации
              дизайн проекта
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
