import image1 from "../../assets/images/projects/project2/project2-1.webp";
import image2 from "../../assets/images/projects/project2/project2-2.webp";
import image3 from "../../assets/images/projects/project2/project2-3.webp";
import image4 from "../../assets/images/projects/project2/project2-4.webp";
import image5 from "../../assets/images/projects/project2/project2-5.webp";
import image6 from "../../assets/images/projects/project2/project2-6.webp";
import image7 from "../../assets/images/projects/project2/project2-7.webp";
import image8 from "../../assets/images/projects/project2/project2-8.webp";
import image9 from "../../assets/images/projects/project2/project2-9.webp";
import image10 from "../../assets/images/projects/project2/project2-10.webp";

export const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
];

const IndexProject2 = (index) => images[index % images.length];

export default IndexProject2;
